import SecondaryPagesBanner from '../../components/SecondaryPagesBanner';
import MainBlock from './MainBlock';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';

function SatelliteSchool() {
  const { schoolName } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, ["load"]);

  let headerText = [<Trans  i18nKey="welcome"/>, <Trans ns="sateliteSchools" i18nKey={schoolName}/>];

  return(
      <>
        <SecondaryPagesBanner header={headerText} text={<Trans ns="sateliteSchools" i18nKey={`${schoolName} contacts`} />}/>
        <MainBlock />
      </>
  )
}

export default SatelliteSchool;