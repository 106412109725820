import MainBlockLayout from '../../components/MainBlockLayout';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image'
import inna from '../../images/ourTeam/Inna.jpeg';
import innaLarge from '../../images/Inna.jpeg';
import lyudmylaKavchak from "../../images/ourTeam/lyudmyla_kavchak.jpeg";
import lyudmylaKavchakLarge from "../../images/ourTeam/ludmyla_kavchak_large.jpeg";
import olenaSvitlyk from "../../images/ourTeam/olena_svitlyk.jpeg";
import olenaSvitlykLarge from "../../images/ourTeam/olena_svitlyk_large.jpeg";
import lesyaHorchyn from "../../images/ourTeam/lesya_horchyn.jpeg";
import lesyaHorchynLarge from "../../images/ourTeam/lesya_horchyn_large.jpeg";
import vitvitska from "../../images/ourTeam/vitvitska.jpeg";
import vitvitskaLarge from "../../images/ourTeam/vitvitska_large.jpeg";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Trans } from 'react-i18next';

const OUR_TEAM_LIST = {
    option1: {
        photo: inna,
        photo_large: innaLarge,
        name: "inna hryhorovych"
    },
    option2: {
        photo: vitvitska,
        photo_large: vitvitskaLarge,
        name: "Tatyana Vitvitska"
    },
    option3: {
        photo: lyudmylaKavchak,
        photo_large: lyudmylaKavchakLarge,
        name: "Lyudmyla Kavchak"
    },
    option4: {
        photo: olenaSvitlyk,
        photo_large: olenaSvitlykLarge,
        name: "olena svitlyk"
    },
    option5: {
        photo: lesyaHorchyn,
        photo_large: lesyaHorchynLarge,
        name: "lesya horchyn"
    },
}

function MainBlock() {
    const [show, setShow] = useState(false);
    const [showModalINformation, setShowModalINformation] = useState('option1');

    function handleModal(modalName) {
        setShowModalINformation(modalName)
        setShow(true)
    }

    function generateCoreTeam() {
        let arrayOfTeam = []

        for (let key in OUR_TEAM_LIST) {
            if(key === 'option1') continue;
            
            arrayOfTeam.push(
                <Card className="cardsBackground">
                    <Card.Img variant="top" src={OUR_TEAM_LIST[key].photo} style={{ width: '40%', marginLeft: '30%', clipPath: 'circle()', maxHeight: '95px' }} height="auto" />
                    <Card.Body>
                        <Card.Title><strong><Trans ns="coreTeam" i18nKey={`${OUR_TEAM_LIST[key].name}`} /></strong></Card.Title>
                        <Card.Text>
                            <Trans ns="coreTeam" i18nKey={`${OUR_TEAM_LIST[key].name} possition`} />
                        </Card.Text>
                    </Card.Body>
                    <Button variant="light" onClick={() => handleModal(key)} style={{ width: '60%', padding: '3%', marginBottom: '3%' }}>
                        <Trans ns="coreTeam" i18nKey="About Me"></Trans>
                    </Button>
                </Card>
            )
        }
        return arrayOfTeam;
    }

    return (
        <>
            <Modal show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                size="lg"
                style={{ fontFamily: 'Calibri, Candara, Segoe, “Segoe UI”, Optima, Arial, sans-serif' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2><Trans ns="coreTeam" i18nKey={OUR_TEAM_LIST[showModalINformation].name}></Trans><br /></h2>
                        <span style={{ fontSize: 'large' }}><Trans ns="coreTeam" i18nKey={`${OUR_TEAM_LIST[showModalINformation].name} possition`} /></span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='SiderbarBackground'>
                    <Row md={12}>
                        <Col md={12}>
                            <Col md={12}>
                                <p><Image style={{ float: 'left', marginRight: '40px' }} src={OUR_TEAM_LIST[showModalINformation].photo_large} width='40%' height='40%' /></p>
                            </Col>
                            <Col md={11}>
                                <p><b><Trans ns="coreTeam" i18nKey='background' /></b> <Trans ns="coreTeam" i18nKey={`${OUR_TEAM_LIST[showModalINformation].name} background`} /></p>
                                <p><b><Trans ns="coreTeam" i18nKey='drives me' /></b> <Trans ns="coreTeam" i18nKey={`${OUR_TEAM_LIST[showModalINformation].name} drives me`} /></p>
                            </Col>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Row className="align-items-center justify-content-center directorCard" >
            <Card className="cardsBackground">
                    <Card.Img variant="top" src={inna} style={{ width: '40%', marginLeft: '30%', clipPath: 'circle()'}} height="auto" />
                    <Card.Body>
                        <Card.Title><strong><Trans ns="coreTeam" i18nKey={`inna hryhorovych`} /></strong></Card.Title>
                        <Card.Text>
                            <Trans ns="coreTeam" i18nKey={`inna hryhorovych possition`} />
                        </Card.Text>
                    </Card.Body>
                    <Button variant="light" onClick={() => handleModal('option1')} style={{ width: '60%', padding: '3%', marginBottom:'3%' }}>
                        <Trans ns="coreTeam" i18nKey="About Me"></Trans>
                    </Button>
                </Card>
            </Row>
            <div style={{display: 'flex', justifyContent: 'center', paddingTop: '3%'}}>
                <h1>Core Team</h1>
            </div>
            <Row style={{display: 'flex', justifyContent: 'center'}}>
                {generateCoreTeam()}
            </Row>

        </>
    )
}

export default MainBlockLayout(MainBlock);