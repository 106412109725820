
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Trans, withTranslation } from 'react-i18next';
import { PARTNERS } from '../../data/ImagesData'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Partners() {
    const RESPONSIVE = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 8,
            slidesToSlide: 8
        },
        desktopSmall: {
            breakpoint: { max: 1485, min: 1126 },
            items: 6,
            slidesToSlide: 6
        },
        tabletBig: {
            breakpoint: { max: 1125, min: 952 },
            items: 4,
            slidesToSlide: 6
        },
        tablet: {
            breakpoint: { max: 951, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };

    let generateImages = () => {
        return PARTNERS.map(partner => (<div>
            <a href={partner[1]} target="_blank">
                <img src={`${process.env.PUBLIC_URL}/images/partners/${partner[0]}`} width="150px" height="auto" />
            </a>
        </div>))
    }

    return (
        <>
            <Row className='partnersPage'>
                <h1 className='justify-content-center d-flex'><Trans i18nKey='Our partners' /></h1>
                <Col md={1}></Col>
                <Col md={10} className="justify-content-center">
                    <Carousel autoPlay={true} infinite autoPlaySpeed={4000} showDots={true} arrows={false}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        responsive={RESPONSIVE}>
                        {generateImages()}
                    </Carousel>
                </Col>
            </Row>
        </>
    );
}

export default withTranslation()(Partners);