import MainBlockLayout from '../../components/MainBlockLayout';
import { Row, Col, Nav, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';
import "react-image-gallery/styles/css/image-gallery.css";
import React, { useState } from "react";
import { YEAR_2023 } from '../../data/satteliteImagesData';
import { generateImageTabs } from "../../utils/common";
import NewsLetterAccordion from './components/NewsLetterAccordion';
import { useSelector } from 'react-redux';

function MainBlock() {
    const { schoolName } = useParams();
    let [loading, setLoading] = useState(true);
    const [showBrochure, setShowBrochure] = useState(false);
    
    const NEWSLETTERS = useSelector(state => state.satelliteSchools.schools_newsletters);
    const newsletter = NEWSLETTERS[schoolName];
    const schoolImages = YEAR_2023[schoolName];

    let addComponent = () => {
        if(schoolName === "tunbridge"){
            return [<><Trans ns="sateliteSchools" i18nKey={`${schoolName} form`} /> <a href="https://forms.gle/iyNw8YurMwJLqX1Z8">https://forms.gle/iyNw8YurMwJLqX1Z8 </a></>]
        }
     }

    return (
        <>
            <Row>
                <Col>
                    <h2 className="justify-content-center d-flex mt-3"><Trans i18nKey="about us" /></h2>
                    <p>
                        <Trans ns="sateliteSchools" i18nKey={`${schoolName} about`} />
                        {addComponent()}
                    </p>
                </Col>
                <h2 className="justify-content-center d-flex mt-5"><Trans i18nKey="Gallery" /></h2>
                <div style={{ marginTop: "25px" }}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="2023">
                        <Row className="justify-content-center d-flex">
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="2023" onClick={() => setLoading(true)}>2023</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                        <Row className="justify-content-center d-flex">
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="2023">
                                        {generateImageTabs(schoolImages, loading, setLoading, "right")}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
                { newsletter ?
                <NewsLetterAccordion newsLetters={newsletter} showBrochure={showBrochure} setShowBrochure={setShowBrochure}/> 
                : "" } 
            </Row>
        </>
    )
}

export default MainBlockLayout(MainBlock);