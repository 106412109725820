import { createSlice } from '@reduxjs/toolkit';
import { eventsList } from './data/eventsDate';

const initialState = {
    calendar: eventsList()
}

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        setCalendar: (state, action) => {
            state.calendar = action.payload;
        }
    },
});

// Action creators are generated for each case reducer function
export const { setCalendar } = calendarSlice.actions

export default calendarSlice.reducer