import SecondaryPagesImage from '../../components/SecondaryPagesImage';
import SecondaryPagesBanner from '../../components/SecondaryPagesBanner';
import MainBlock from './MainBlock';
import slide1 from '../../images/slide1.jpeg';
import { t } from 'i18next';

function News() {
    return(
      <>
        <SecondaryPagesImage image={slide1} />
        <SecondaryPagesBanner header={t('News')} 
        text={t('')}/>
        <MainBlock />
      </>
    );
}

export default News;