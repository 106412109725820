import { Row, Col, Accordion, Button, Modal } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import NewsLetterModal from './NewsLetterModal';
import React, { useState } from "react";

function NewsLetterButton({newsLetter}) {
    let [showBrochure, setShowBrochure] = useState(false);

    return (
        <>
            <Button variant="success" style={{ marginRight: '15px' }} onClick={() => setShowBrochure(true)}>
                <Trans i18nKey="Preview" />
            </Button>
            <NewsLetterModal pdfUrl={newsLetter.pdf} name={newsLetter.originalName} showBrochure={showBrochure} setShowBrochure={setShowBrochure} />
        </>
    );
}

export default NewsLetterButton;