import { Trans } from 'react-i18next'
import MainBlockLayout from '../../components/MainBlockLayout';

function MainBlock() {
  return(
      <>
      <p><Trans i18nKey="Saturday community school" /></p>
      <p><Trans i18nKey="school year is split" /></p>
      <ul>
          <li><Trans i18nKey="First Term starts"/></li>
          <li><Trans i18nKey="Second Term begins"/></li>
      </ul>
      <p><Trans i18nKey="School Day" /></p>
      <ul>
          <li><Trans i18nKey="At 10am"/></li>
          <li><Trans i18nKey="academic Saturday consists"/></li>
      </ul>
      <p><Trans i18nKey="Children are dropped off" /></p>
      <p><Trans i18nKey="For Early Years and KS1" /></p>
      </>
  )
}

export default MainBlockLayout(MainBlock);