import { Trans } from 'react-i18next'
import { generateSimpleList } from "../../utils/common";
import MainBlockLayout from '../../components/MainBlockLayout';

const EXAM_LIST = ['Ukrainian language', 'Ukrainian literature', 'History of Ukraine'] 

function MainBlock() {
  return(
      <>
      <p><Trans i18nKey="school is split into" /></p>
      <p><Trans i18nKey="During their school life" /></p>
      <ul>
          {generateSimpleList(EXAM_LIST)}
      </ul>
      <p><Trans i18nKey="two research papers" /></p>
      <p><Trans i18nKey="completion of their studies" /></p>
      <p><Trans i18nKey="All aspects of pupils" /></p>
      <p><Trans i18nKey="very proud that" /></p>
      </>
  )
}

export default MainBlockLayout(MainBlock);
