import { createSlice } from '@reduxjs/toolkit';
import { newsList } from './data/newsData';

const initialState = {
    news: newsList(),
    isLoading: true,
    error: null,
};

export const newsSlice = createSlice({
    name: 'newsAndMedia',
    initialState,
    reducers: {
        setNews: (state, action) => {
            state.news = action.payload;
            state.isLoading = false;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
});

export const { setNews, setError } = newsSlice.actions;

export default newsSlice.reducer;