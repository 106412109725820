import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import * as enUK from './locales/en/translations.json';
import * as enUKMessage from './locales/en/message-from.json';
import * as enUKNavigation from './locales/en/navigation.json';
import * as enUKsateliteSchools from './locales/en/satelite-schools.json';
import * as enUKCoreTeam from './locales/en/core-team.json';

import * as uaUA from './locales/ua/translations.json';
import * as uaMessage from './locales/ua/message-from.json';
import * as uaNavigation from './locales/ua/navigation.json';
import * as uaSateliteSchools from './locales/ua/satelite-schools.json';
import * as uaCoreTeam from './locales/ua/core-team.json';


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    // lng: "en", // if you're using a language detector, do not define the lng option
    load: "languageOnly",
    supportedLngs: ['ua', 'en'],
    resources: {
      en: {
        translation: enUK,
        messageFrom: enUKMessage,
        navigation: enUKNavigation,
        sateliteSchools: enUKsateliteSchools,
        coreTeam: enUKCoreTeam
      },

      ua: {
        translation: uaUA,
        messageFrom: uaMessage,
        navigation: uaNavigation,
        sateliteSchools: uaSateliteSchools,
        coreTeam: uaCoreTeam
      }
    },
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
