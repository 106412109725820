import { Trans } from 'react-i18next'
import MainBlockLayout from '../../components/MainBlockLayout';

function MainBlock() {
  return (
    <>
      <p><Trans i18nKey="nothing impossible" /></p>
      <p><Trans i18nKey="maintain high standards" /></p>
      <p><Trans i18nKey="Leaders of the school keep" /></p>
      <p><Trans i18nKey="The curriculum we use" /></p>
      <p><Trans i18nKey="school offers a unique opportunity" /></p>
      <p><Trans i18nKey="currently a huge issue" /></p>
      <p><Trans i18nKey="recognised this a few years" /></p>
      <h4><Trans i18nKey="Primary School" /></h4>
      <h5><Trans i18nKey="For Reception and Year 1" /></h5>
      <p><Trans i18nKey="Alphabet, basics of phonics" /></p>
      <h5><Trans i18nKey="Year 2 to Year 4" /></h5>
      <p><Trans i18nKey="the Primary School curriculum" /></p>
      <p><Trans i18nKey="discussion topics may vary" /></p>
      <h4><Trans i18nKey="Senior School" /></h4>
      <h5><Trans i18nKey="Years 5-6" /></h5>
      <p><Trans i18nKey="Speaking skills" /></p>
      <h5><Trans i18nKey="Years 7-8" /></h5>
      <p><Trans i18nKey="History of Ukraine and Speaking Skills" /></p>
      <h5><Trans i18nKey="Years 9-10" /></h5>
      <p><Trans i18nKey="History of Ukraine and Geography of Ukraine" /></p>
      <p><Trans i18nKey="At the end of Year 9" /></p>
      <p><Trans i18nKey="they put together two research papers" /></p>
      <p><Trans i18nKey="successful completion of their studies" /></p>
    </>
  )
}

export default MainBlockLayout(MainBlock);
