import { useTranslation } from 'react-i18next';
import inna from '../../images/Inna.jpeg';
import MainBlockLayout from '../../components/MainBlockLayout';
import { Trans } from 'react-i18next'

const SUPPORT_AND_COLABORATION_LIST = [
  ['AUGB', 'https://www.augb.co.uk'],
  ['AUGB London', ''],
  ['teachers and educators', 'https://www.augb.co.uk/association-of-ukrainian-teachers-and-educators.php'],
  ['catholic cathedral', 'https://parish.rcdow.org.uk/ukrainianchurch'],
  ['orthodox church', 'http://www.ukrainiansintheuk.info/eng/03/uaoc-e.htm'],
  ['cym', 'https://cym.org/'],
  ['plast', 'http://www.plast.org.uk/eng/scoutlaws.aspx'],
  ['AUW', 'https://www.augb.co.uk/ukrainian-women.php'],
  ['euromaidan', 'https://www.facebook.com/london.maidan'],
  ['institute', 'http://ukrainianinstitute.org.uk/'],
  ['university', 'https://ucu.edu.ua/en/'],
]

const imageStyle = {
  float: 'left',
  width: '20%',
  height: '20%',
  marginRight: '20px',
  marginBottom: '15px',
  marginTop: '15px'
};

function MainBlock() {
  function generateSupportList(list) {
    return list.map(([key, href], index) => (
      <li key={index}>
        <Trans i18nKey={key} ns='messageFrom' />: <a href={href}>{href}</a>
      </li>
    ));
  }

  return (
    <>
    <p>
      <img style={imageStyle} src={inna} alt="Inna"/>
    </p>
    <p><Trans i18nKey='We are extremely proud' ns='messageFrom'/></p>
      <p><Trans i18nKey='highly qualified and experienced staff' ns='messageFrom'/></p>
      <p><Trans i18nKey='would not have existed' ns='messageFrom'/></p>
      <ul>
        {generateSupportList(SUPPORT_AND_COLABORATION_LIST)}
      </ul>
      <p><Trans i18nKey='heavily relient' ns='messageFrom'/></p>
      <p><Trans i18nKey='we are proud' ns='messageFrom'/></p>
      <p><Trans i18nKey='hope to enjoy' ns='messageFrom'/></p>
    </>
  )
}

export default MainBlockLayout(MainBlock);