import './App.css';
import Menu from './components/Menu';
import HomePage from './pages/HomePage';
import Footer from './components/Footer';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom"
import MessageFromHT from './pages/MessageFromHT';
import OurHistory from './pages/OurHistory';
import OurTeam from './pages/OurTeam';
import ParentsCommittee from './pages/ParentsCommittee';
import MediaAndNews from './pages/MediaAndNews';
import Vacancies from './pages/Vacancies';
import SchoolProfile from './pages/SchoolProfile';
import SchoolCurriculum from './pages/SchoolCurriculum';
import SchoolDayAndTerms from './pages/SchoolDayAndTerms';
import SchoolPolicies from './pages/SchoolPolicies';
import ContactUs from './pages/ContactUs';
import Admissions from './pages/Admissions';
import Calendar from './pages/Calendar';
import Gallery from './pages/Gallery';
import News from './pages/News';
import SatelliteSchools from './pages/SatelliteSchools';
import SatelliteSchool from './pages/SatelliteSchool';
import SideBar from './components/SideBar';
import { withTranslation } from 'react-i18next';
import SocialMedia from './components/SocialMedia';
import AboutUs from './pages/AboutUs';
import SchoolInfo from './pages/SchoolInfo';
import RootPage from './pages/RootPage';
import Banner from './components/Banner';

function App() {
  return (
    <div>
      <BrowserRouter>
          <SideBar className="d-block d-sm-block d-md-none SiderbarBackground" />
          <Menu style={{ width: 'auto' }}/>
          <Banner />
          <SocialMedia />
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/" element={<RootPage/>}>
              <Route path='contact-us' element={<ContactUs />} />
              <Route path='admissions' element={<Admissions />} />
              <Route path='calendar' element={<Calendar />} />
              <Route path='gallery' element={<Gallery />} />
              <Route path='news' element={<News />} />
              <Route path='satellite-schools' element={<SatelliteSchools />} />
            </Route>
            <Route path='school/:schoolName' element={<SatelliteSchool />} />
            <Route path='/about-us' element={<AboutUs />}>
              <Route path='message-from' element={<MessageFromHT />} />
              <Route path='our-history' element={<OurHistory />} />
              <Route path='our-team' element={<OurTeam />} />
              <Route path='st-marys-in-media-and-news' element={<MediaAndNews />} />
              <Route path='vacancies' element={<Vacancies />} />
            </Route>
            <Route path='/school-info' element={<SchoolInfo/>}>
              <Route path='school-profile' element={<SchoolProfile />} />
              <Route path='school-curriculum' element={<SchoolCurriculum />} />
              <Route path='school-day-and-terms' element={<SchoolDayAndTerms />} />
              <Route path='school-policies' element={<SchoolPolicies />} />
              <Route path='parent-teacher-association' element={<ParentsCommittee />} />
            </Route>
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
          <Footer />
      </BrowserRouter>
    </div>
  );
}

export default withTranslation()(App);
