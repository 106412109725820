import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { t } from 'i18next';
import FormField from './FormField';
import { useSelector } from 'react-redux';

function FormComponent({ onFormSubmit, allowedToSend, handleInputChange }) {
    const formState = useSelector(state => state.form);
    const captchaValid = formState.captcha.isValid;
    const validated = formState.validated;

    return (
        <>
            <Form noValidate validated={validated} onSubmit={onFormSubmit}>
                <h2>{t('Write to us')}</h2>
                <FormField name="name" type="text" label={t('Your Name')} placeholder={t('Your Name')} isInvalid={formState.name.isTouched && !formState.name.isValid} onChange={handleInputChange} />
                <FormField name="email" type="email" label={t('Your Email')} placeholder={t('Your Email')} isInvalid={formState.email.isTouched && !formState.email.isValid} onChange={handleInputChange} />
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>{t('Message')}</Form.Label>
                     <Form.Control required as="textarea" rows={6} isInvalid={formState.textField.isTouched && !formState.textField.isValid} name="textField" onChange={(e) => handleInputChange(e)} />
                 </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check required name="agreeOnSchoolDetails" type="checkbox" label={t('agree for school to use contact details provided')} onChange={(e) => handleInputChange(e)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check required name="agreeOnNews" type="checkbox" label={t('agree for school')} onChange={(e) => handleInputChange(e)} />
                </Form.Group>


                <Button variant="primary" type="submit" style={{ marginTop: '20px' }} disabled={!allowedToSend || !captchaValid}>
                    {t('Send')}
                </Button>
            </Form>
        </>
    );
}

// function FormComponent({ onFormSubmit, validated, allowedToSend, captchaValid, handleInputChange, formState }) {
//     return (
//         <>
//             <Form noValidate validated={validated} onSubmit={onFormSubmit}>
//                 <h2>{t('Write to us')}</h2>
//                 <Form.Group className="mb-3" controlId="formBasicEmail">
//                     <Form.Label>{t('Your Name')}</Form.Label>
//                     <Form.Control required name="name" type="text" placeholder={t('Your Name')} isInvalid={formState.name.isTouched && !formState.name.isValid} onChange={(e) => handleInputChange(e)} />
//                     <Form.Control.Feedback type="invalid">
//                         {t('please enter name')}
//                     </Form.Control.Feedback>
//                 </Form.Group>

//                 <Form.Group className="mb-3" controlId="formBasicPassword">
//                     <Form.Label>{t('Your Email')}</Form.Label>
//                     <Form.Control required name="email" type="email" placeholder={t('Your Email')} isInvalid={formState.email.isTouched && !formState.email.isValid} onChange={(e) => handleInputChange(e)} />
//                     <Form.Text className="text-muted">
//                         {t('never share')}
//                     </Form.Text>
//                     <Form.Control.Feedback type="invalid">
//                         {t('enter Valid Email')}
//                     </Form.Control.Feedback>
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="formBasicEmail">
//                     <Form.Label>{t('Message')}</Form.Label>
//                     <Form.Control required as="textarea" rows={6} isInvalid={formState.textField.isTouched && !formState.textField.isValid} name="textField" onChange={(e) => handleInputChange(e)} />
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="formBasicCheckbox">
//                     <Form.Check required name="agreeOnSchoolDetails" type="checkbox" label={t('agree for school to use contact details provided')} onChange={(e) => handleInputChange(e)} />
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="formBasicCheckbox">
//                     <Form.Check required name="agreeOnNews" type="checkbox" label={t('agree for school')} onChange={(e) => handleInputChange(e)} />
//                 </Form.Group>


//                 <Button variant="primary" type="submit" style={{ marginTop: '20px' }} disabled={!allowedToSend || !captchaValid}>
//                     {t('Send')}
//                 </Button>
//             </Form>
//         </>
//     );
// }

export default FormComponent;