import endOfScool from '../../images/ourHistory/End-of-School-Year-1967-1968.jpeg';
import oldLogo from "../../images/ourHistory/old-logo.jpeg";
import motherDay from "../../images/ourHistory/Mothers-Day-celebration-1974-1975.jpeg";
import mychajloSzynkaruk from "../../images/ourHistory/Mychajlo-Szynkaruk---Head-Teacher-at-St.-Marys-Ukrainian-School-1965-2001.jpeg";
import schoolStudents from "../../images/ourHistory/School-students-1982---1992.jpeg";
import MainBlockLayout from '../../components/MainBlockLayout';
import { Trans } from 'react-i18next';

const SIGNIFICANT_CHANGES_LIST = ['re-established', 'curriculum has been', 'learning groups',
  'safeguarding', 'technical equipment', 'expanded beyond books', 'activities',
  'liaison and communication', 'events and participation', 'above changes'];

function MainBlock() {
  function generateList(list) {
    return list.map((item, index) => {
      return (<li key={index}><Trans i18nKey={item}/></li>);
    });
  }

  return (
    <>
    <p><img style={{ float: 'left', width: '30%', height: '30%', marginBottom: '15px', marginRight: '15px' }} src={endOfScool} alt="End of Scholl" /></p>
    <Trans i18nKey="over 60 years"></Trans>
    <p><img style={{ float: 'right', width: '20%', height: '20%', marginLeft: '20px', marginBottom: '15px', marginTop: '15px' }} src={oldLogo} alt="Old Logo"/></p>
    <Trans i18nKey="start creating"></Trans>
    <Trans i18nKey="first try to organise school"></Trans>
    <img style={{ width: '99%', height: '90%' }} src={motherDay} alt="Mother Day" />
    <Trans i18nKey="in 1956"/>
    <p><img style={{ float: 'left', width: '22%', height: '20%', marginRight: '20px', marginBottom: '15px', marginTop: '15px' }} src={mychajloSzynkaruk} alt="Old head master" /></p>
    <Trans i18nKey="in 1965"/>
    <img style={{ width: '99%', height: '70%' }} src={schoolStudents} alt="school Stydents" />
    <Trans i18nKey="in 2001"/>
    <Trans i18nKey="in 2014"/>
     <ul>
        {generateList(SIGNIFICANT_CHANGES_LIST)}
      </ul>
      <p><Trans i18nKey="today our school"/></p>
    </>
  )
}

export default MainBlockLayout(MainBlock);