import { Trans } from 'react-i18next';
import { Row, Col, Nav, Tab } from 'react-bootstrap';
import MainBlockLayout from '../../components/MainBlockLayout';
import { YEAR_2023 } from '../../data/PTAImagesData';
import { generateImageTabs } from "../../utils/common";
import { useParams } from 'react-router-dom';
import React, { useState } from "react";

function MainBlock() {
  const { schoolName } = useParams();
  let [loading, setLoading] = useState(true);

  return (
    <>
      <Trans i18nKey="pta main text"></Trans>
      <h2 className="justify-content-center d-flex mt-5"><Trans i18nKey="our events" /></h2>
      <Tab.Container id="left-tabs-example" defaultActiveKey="2023">
        <Row className="justify-content-center d-flex">
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="2023" onClick={() => setLoading(true)}>2023</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row className="justify-content-center d-flex">
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="2023">
                {generateImageTabs(YEAR_2023.pta, loading, setLoading, "right")}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  )
}

export default MainBlockLayout(MainBlock);