export const NAVIGATION_ABOUT_US = {
    messageFrom: {
        name: 'message from',
        linkTo: '/about-us/message-from'
    },

    ourHistory: {
        name: 'our history',
        linkTo: '/about-us/our-history'
    },

    // ourProjects: {
    //     name: 'our projects',
    //     linkTo: '/'
    // },

    ourTeam: {
        name: 'our team',
        linkTo: '/about-us/our-team'
    },

    news: {
        name: 'media news',
        linkTo: '/about-us/st-marys-in-media-and-news'
    },

    // ourAchivements: {
    //     name: 'our achievements',
    //     linkTo: '/'
    // },

    vacancies: {
        name: 'vacancies',
        linkTo: '/about-us/vacancies'
    }
}

export const NAVIGATION_SCHOOL_INFO = {
    school_profile: {
        name: 'school profile',
        linkTo: '/school-info/school-profile'
    },

    school_curriculum: {
        name: 'school curriculum',
        linkTo: '/school-info/school-curriculum'
    },

    school_day_terms: {
        name: 'school day & terms',
        linkTo: '/school-info/school-day-and-terms'
    },

    // calendar: {
    //     name: 'calendar',
    //     linkTo: '/'
    // },

    school_policies: {
        name: 'school policies',
        linkTo: '/school-info/school-policies'
    },

    parrent: {
        name: 'parent_teacher_association',
        linkTo: '/school-info/parent-teacher-association'
    },

    // family_support: {
    //     name: 'family support',
    //     linkTo: '/'
    // },
}

export const NAVIGATION_SATELLITE_SCHOOLS = {
    satellite_schools: {
        name: 'satellite schools',
        linkTo: '/satellite-schools'
    },

    // our_projects: {
    //     name: 'our projects',
    //     linkTo: '/'
    // },
}


export const NAVIGATION_HOME = {
    admissions: {
        name: 'Admissions',
        linkTo: '/admissions'
    },

    // news: {
    //     name: 'News',
    //     linkTo: '/news'
    // },

    gallery: {
        name: 'Gallery',
        linkTo: '/gallery'
    },

    calendar: {
        name: 'Calendar',
        linkTo: '/calendar'
    },
}