import "react-image-gallery/styles/css/image-gallery.css";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import React, { useState } from "react";
import Tab from 'react-bootstrap/Tab';
import { YEAR_2017, YEAR_2018, YEAR_2019, YEAR_2020, YEAR_2023, YEAR_2024, YEAR_2025 } from '../../data/ImagesData';
import { generateImageTabs } from "../../utils/common";

function MainBlock() {
    let [loading, setLoading] = useState(true);

    return (
        <div style={{ marginTop: "25px" }}>
            <Tab.Container id="left-tabs-example" defaultActiveKey="2023" >
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link disabled eventKey="2025" onClick={() => setLoading(true)}>2025 - Coming Up</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link disabled eventKey="2024" onClick={() => setLoading(true)}>2024 - Coming Up</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2023" onClick={() => setLoading(true)}>2023</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link disabled eventKey="2022" onClick={() => setLoading(true)}>2022 - Coming Up</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link disabled eventKey="2021" onClick={() => setLoading(true)}>2021 - Coming Up</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2020" onClick={() => setLoading(true)}>2020</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2019" onClick={() => setLoading(true)}>2019</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2018" onClick={() => setLoading(true)}>2018</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2017" onClick={() => setLoading(true)}>2017</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="2023">
                                {generateImageTabs(YEAR_2023, loading, setLoading)}
                            </Tab.Pane>
                            <Tab.Pane eventKey="2020">
                                {generateImageTabs(YEAR_2020, loading, setLoading)}
                            </Tab.Pane>
                            <Tab.Pane eventKey="2019" mountOnEnter={true}>
                                {generateImageTabs(YEAR_2019, loading, setLoading)}
                            </Tab.Pane>
                            <Tab.Pane eventKey="2018" mountOnEnter={true}>
                                {generateImageTabs(YEAR_2018, loading, setLoading)}
                            </Tab.Pane>
                            <Tab.Pane eventKey="2017" mountOnEnter={true}>
                                {generateImageTabs(YEAR_2017, loading, setLoading)}
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
}

export default MainBlock;