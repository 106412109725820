import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';

function SecondaryPagesBanner(props) {

  return (
    <>
      <Col className='mainBlock' md={12}>
        <Row>
          <div>
            <h2 style={{ textAlign: 'center', marginTop: '10px'  }}>{props.header}</h2>
          </div>
          <div className='mainBlockText' style={props.customStyle}>
            {props.text}
          </div>
        </Row>
      </Col>
    </>
  )
}

export default SecondaryPagesBanner;