import { t } from 'i18next';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from "react-router-dom";
import { NAVIGATION_ABOUT_US, NAVIGATION_SCHOOL_INFO, NAVIGATION_HOME } from '../data/NavigationData';
import ukrainian from '../images/ukrainian.svg';
import { Trans } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import ukraine from '../images/ukraine.svg';
import uk from '../images/uk.svg';
import LanguageButton from '../components/LanguageButton';
import { Col, Row } from 'react-bootstrap';

function Menu(props) {
    function navigationGeneration(navigationItems) {
        let navigationArray = [];

        for (let key in navigationItems) {
            navigationArray.push(
                <NavDropdown.Item key={key} as={NavLink} to={navigationItems[key].linkTo} className='navMenuItem'>
                    <Trans ns="navigation" i18nKey={navigationItems[key].name} />
                </NavDropdown.Item>
            )
        }

        return navigationArray;
    }
    return (
        <>
            <Navbar fluid sticky="top" className="d-none d-sm-none d-md-block">
                <Row id="menu">
                    <Col md={1} className="justify-content-start d-flex" as={NavLink} to="/">
                        <img
                            src={ukrainian}
                            width="100"
                            height="100"
                            alt="Ukrainian Logo"
                            style={{ marginLeft: '5px' }}
                        />
                    </Col>
                    <Col md={9} className="justify-content-start d-flex" style={{ padding: '0' }}>
                        <Navbar>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto">
                                    <NavDropdown title={<Trans i18nKey="Home" />} className="basic-nav-dropdown" renderMenuOnMount={true}>
                                        {navigationGeneration(NAVIGATION_HOME)}
                                    </NavDropdown>
                                    <NavDropdown title={<Trans i18nKey="about us" />} className="basic-nav-dropdown" renderMenuOnMount={true}>
                                        {navigationGeneration(NAVIGATION_ABOUT_US)}
                                    </NavDropdown>
                                    <NavDropdown title={<Trans i18nKey="school info" />} className="basic-nav-dropdown" renderMenuOnMount={true}>
                                        {navigationGeneration(NAVIGATION_SCHOOL_INFO)}
                                    </NavDropdown>
                                    <div className="basic-nav-dropdown nav-item dropdown">
                                        <Nav.Link as={NavLink} to="/satellite-schools" className='navMenuItem'><Trans i18nKey="satellite schools" /></Nav.Link>
                                    </div>
                                    <div className="basic-nav-dropdown nav-item dropdown">
                                        <Nav.Link as={NavLink} to="/contact-us" className='navMenuItem'><Trans i18nKey="Contact Us" /></Nav.Link>
                                    </div>
                                    <Button size="md" className='supportUsButton d-none d-sm-none d-md-flex' href="https://donate.stmarysukrschool.co.uk/donation/custom-donation/" target="_blank"><Trans ns="navigation" i18nKey="Support Us"
                                    /></Button>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </Col>
                    <Col md={2}>
                        <Row className="mb-3">
                            <Col className="justify-content-center d-flex">
                                <LanguageButton localisation="en" logo={uk} alt="UK flag" />
                                <LanguageButton localisation="ua" logo={ukraine} alt="Ukrainian flag" />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="justify-content-center d-flex established"><Trans i18nKey="Established" /></Col>
                        </Row>
                    </Col>
                </Row>
            </Navbar>
        </>
    )
}

export default Menu;