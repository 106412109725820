import MainBlockLayout from '../../components/MainBlockLayout';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { useState, useRef, useCallback } from 'react';
import { t } from 'i18next';
import emailjs from '@emailjs/browser';
import axios from 'axios';
import Swal from 'sweetalert2';
import FormComponent from './components/Form';
import CaptchaComponent from './components/Captcha';
import { useSelector, useDispatch } from 'react-redux'
import { nameChange, emailChange, textFieldChange, captchaChange, agreeOnNewsChange, agreeOnSchoolDetailsChange, validatedChange, resetFormState } from '../../store/slices/ContactUs/slice';

function MainBlock() {
    const SERVICE_ID = "service_lioumlf";
    const TEMPLATE_ID = "template_3d1p8t8";
    const CAPTCHA_VERIFICATION_URL = 'https://wandering-plum-girdle.cyclic.app/send_verification_token';

    const contactUsForm = useSelector(state => state.form);
    const dispatcher = useDispatch();

    const captchaRef = useRef(null);

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (contactUsForm.name.isValid && contactUsForm.email.isValid && contactUsForm.textField.isValid && contactUsForm.captcha.isValid) {
            dispatcher(validatedChange(true));
            sendEmail();
            dispatcher(resetFormState());
            captchaRef.current.reset();
        }
    };

    function sendEmail(form) {
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
            .then((result) => {
                Swal.fire(
                    {
                        title: t('Thanks'),
                        icon: 'success'
                    }
                )
            }, (error) => {
                Swal.fire(
                    {
                        title: error.text,
                        icon: 'error'
                    }
                )
            });
    }

    const handleInputChange = useCallback((event) => {
        const { name, value } = event.target;
        let isValid;
        if (name === 'name') {
            isValid = value.length > 2;
            dispatcher(nameChange({ value, isValid, isTouched: true }));
        } else if (name === 'email') {
            isValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            dispatcher(emailChange({ value, isValid, isTouched: true }));
        } else if (name === 'textField') {
            isValid = value.length > 10;
            dispatcher(textFieldChange({ value, isValid, isTouched: true }));
        }
    }, []);

    async function verify() {
        let response = await captchaRef.current.getResponse();
        handleCaptchaSubmit(response);
    }

    const handleAgreeCheckboxes = useCallback((event) => {
        const { name, checked } = event.target;
        if (name === 'agreeOnSchoolDetails') {  
            dispatcher(agreeOnSchoolDetailsChange(checked));
        } else if (name === 'agreeOnNews') {
            dispatcher(agreeOnNewsChange(checked));
        }
    }, []);

    let allowedToSend = contactUsForm.name.isValid && contactUsForm.email.isValid && contactUsForm.textField.isValid && contactUsForm.agreeOnSchoolDetails && contactUsForm.agreeOnNews;

    const handleCaptchaSubmit = useCallback(async (token) => {
        try {
            const res = await axios.post(CAPTCHA_VERIFICATION_URL, { token });
            if (res.status === 200 && res.data && res.data === "Human") {
                dispatcher(captchaChange({ value: token, isValid: true }));
            } else {
                Swal.fire({
                    title: 'Something went wrong, please refresh the page, all cal us: 02037954966',
                    icon: 'error'
                });
            }
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }, []);

    return (
        <>
            <Container>
                <Row xs={1} sm={1} md={2}>
                    <Row>
                        <h2>{t('Find us')}</h2>
                        <span>{t('nearest tube station')}</span>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.393140096576!2d-0.21312068475787943!3d51.50600301862722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760fe78dcf7c39%3A0x78dce29ac86f3e99!2zMTU0IEhvbGxhbmQgUGFyayBBdmUsIExvbmRvbiBXMTEgNFVILCDQktC10LvQuNC60L7QsdGA0LjRgtCw0L3RltGP!5e0!3m2!1suk!2sua!4v1523359297699" width="50%" height="500" frameborder="0" title="Google Map"></iframe>
                    </Row>
                    <FormComponent
                        onFormSubmit={handleSubmit}
                        handleInputChange={handleInputChange}
                        onCheckboxChange={handleAgreeCheckboxes}
                        allowedToSend={allowedToSend}
                    />
                    <CaptchaComponent
                        onCaptchaVerify={verify}
                        captchaRef={captchaRef}
                        captchaValid={contactUsForm.captcha.isValid}
                        allowedToSend={allowedToSend}
                    />
                </Row>
            </Container>

        </>
    )
}

export default MainBlockLayout(MainBlock);