import SecondaryPagesImage from '../../components/SecondaryPagesImage';
import SecondaryPagesBanner from '../../components/SecondaryPagesBanner';
import { Trans, withTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import slide1 from '../../images/slide1.jpeg';
import parents from '../../images/parents.jpeg';
import ourTeam from '../../images/our-team.jpeg';

function MessageFromHT() {
    let { pathname } = useLocation();

    pathname = pathname.replace('/', '')

    const MAIN_BLOCK_DATA = {
       'admissions': {
          header: `Admissions`,
          image: slide1,
          text: `Admissions contact`
       },
       'gallery': {
        header: `Gallery`,
        image: slide1,
        text: `The Gallery is full of a wealth of photographs`
       },
       'calendar': {
        header: 'Calendar',
        image: slide1,
        text: ''
       },
       'satellite-schools': {
        header: 'satellite schools',
        image: parents,
        text: ''
       },
       'contact-us': {
        header: 'Contact Us',
        image: ourTeam,
        text: 'Our Address'
       }
    };

    let style = {textAlign: 'center', fontSize: '18px' };


    return(
      <>
        <SecondaryPagesImage image={MAIN_BLOCK_DATA[pathname].image} />
        <SecondaryPagesBanner header={<Trans i18nKey={MAIN_BLOCK_DATA[pathname].header} customStyle={style}/>} 
        text={<Trans i18nKey={MAIN_BLOCK_DATA[pathname].text}/>}/>
        <Outlet />
      </>
    );
}

export default withTranslation()(MessageFromHT);