import { Trans } from 'react-i18next'
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import GDPR_policy_2022 from '../../files/GDPR_policy_2022.pdf';
import safeguarding_children from '../../files/safeguarding_children_child_protection_policy.pdf';
import Whistleblowing_Policy from '../../files/Whistleblowing_Policy.pdf';
import First_Aid_Policy from '../../files/First_Aid_Policy.pdf';
import E_Safety_policy from '../../files/E_Safety_policy.pdf';
import Anti_bullying_policy from '../../files/Anti_bullying_policy.pdf';
import Complaint_policies_new from '../../files/Complaint_policies_new.pdf';
import Health_and_Safety_Policy from '../../files/Health_and_Safety_Policy.pdf';
import equality_and_diversity_policy from '../../files/equality_and_diversity_policy.pdf';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import MainBlockLayout from '../../components/MainBlockLayout';
import Accordion from 'react-bootstrap/Accordion';
import { Col } from 'react-bootstrap';

function MainBlock() {
    const [showgdprPolicy, setShowgdprPolicy] = useState(false);
    const [showSafeguardingChildren, setShowSafeguardingChildren] = useState(false);
    const [showWhistleblowingPolicy, setShowWhistleblowingPolicy] = useState(false);
    const [showFirstAidPolicy, setShowFirstAidPolicy] = useState(false);
    const [showESafetypolicy, setShowESafetypolicy] = useState(false);
    const [showAntiBullyingPolicy, setShowAntiBullyingPolicy] = useState(false);
    const [showComplaintPoliciesNew, setShowomplaintPoliciesNew] = useState(false);
    const [showHealthSafetyPolicy, setShowHealthSafetyPolicy] = useState(false);
    const [showEqualityDiversityPolicy, setShowEqualityDiversityPolicy] = useState(false);

    const POLICIES_LIST = {
        gdprPolicy: {
            name: "GDPR Policy",
            fileName: "GDPR policy 2022.pdf",
            previewLink: GDPR_policy_2022,
            downloadLink: "https://www.dropbox.com/s/eqk5n50yj8a8zsr/GDPR%20policy%202022.pdf?dl=1",
            stateFunction: showgdprPolicy,
            handler: setShowgdprPolicy
        },

        safeguardingChildren: {
            name: "Safeguarding Children - Child Protection Policy",
            fileName: "Safeguarding Children Child Protection Policy.pdf",
            previewLink: safeguarding_children,
            downloadLink: "https://www.dropbox.com/s/u9b0qxs2t7i2gbd/safeguarding_children_child_protection_policy.pdf?dl=1",
            stateFunction: showSafeguardingChildren,
            handler: setShowSafeguardingChildren
        },

        WhistleblowingPolicy: {
            name: "Whistleblowing Policy",
            fileName: "Whistleblowing Policy.pdf",
            previewLink: Whistleblowing_Policy,
            downloadLink: "https://www.dropbox.com/s/kn2enhng6tazqs3/Whistleblowing%20Policy.pdf?dl=1",
            stateFunction: showWhistleblowingPolicy,
            handler: setShowWhistleblowingPolicy
        },

        First_Aid_Policy: {
            name: "First Aid Policy",
            fileName: "First Aid Policy.pdf",
            previewLink: First_Aid_Policy,
            downloadLink: "https://www.dropbox.com/s/m0zubs7h3fvn2ur/First%20Aid%20Policy.pdf?dl=1",
            stateFunction: showFirstAidPolicy,
            handler: setShowFirstAidPolicy
        },

        E_Safety_policy: {
            name: "E-Safety policy",
            fileName: "E-Safety policy.pdf",
            previewLink: E_Safety_policy,
            downloadLink: "https://www.dropbox.com/s/llov5l6gwtvp2ek/E-Safety%20policy.pdf?dl=1",
            stateFunction: showESafetypolicy,
            handler: setShowESafetypolicy
        },

        Anti_bullying_policy: {
            name: "Anti-bullying policy",
            fileName: "Anti-bullying policy.pdf",
            previewLink: Anti_bullying_policy,
            downloadLink: "https://www.dropbox.com/s/tpi3crsblwsk1ld/Anti-bullying%20policy.pdf?dl=1",
            stateFunction: showAntiBullyingPolicy,
            handler: setShowAntiBullyingPolicy
        },

        Complaint_policies_new: {
            name: "Complaint policies",
            fileName: "Complaint policies new.pdf",
            previewLink: Complaint_policies_new,
            downloadLink: "https://www.dropbox.com/s/o2bij9fy4hl0f02/Complaint%20policies%20%20new.pdf?dl=1",
            stateFunction: showComplaintPoliciesNew,
            handler: setShowomplaintPoliciesNew
        },

        Health_and_Safety_Policy: {
            name: "Health and Safety Policy",
            fileName: "Health and Safety Policy September 2022 St Marys.pdf",
            previewLink: Health_and_Safety_Policy,
            downloadLink: "https://www.dropbox.com/s/09ykuwbd8hzev5j/Health%20and%20Safety%20Policy%20September%202022%20St%20Marys.pdf?dl=1",
            stateFunction: showHealthSafetyPolicy,
            handler: setShowHealthSafetyPolicy
        },

        equality_and_diversity_policy: {
            name: "equality and diversity policy",
            fileName: "equality and diversity policy.pdf",
            previewLink: equality_and_diversity_policy,
            downloadLink: "https://www.dropbox.com/s/8qzdsgr1016bajy/equality-and-diversity%20policy.pdf?dl=1",
            stateFunction: showEqualityDiversityPolicy,
            handler: setShowEqualityDiversityPolicy
        }
    }

    function createPolicyLayout() {
        let listArray = [];

        for (let key in POLICIES_LIST) {
            let valueInObject = POLICIES_LIST[key];

            listArray.push(
                <>
                    <Accordion>
                        <Accordion.Item eventKey={key}>
                            <Accordion.Header>{POLICIES_LIST[key].name}</Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col sm={12}>
                                        {POLICIES_LIST[key].fileName}
                                    </Col>
                                    <Col sm={12} className="mt-2">
                                        <Button variant="success" onClick={() => valueInObject.handler(true)} style={{ marginRight: '15px' }}>
                                            <Trans i18nKey="Preview" />
                                        </Button>

                                        <a href={valueInObject.downloadLink} download ><Button variant="primary"><Trans i18nKey="Download" /></Button></a>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Modal show={valueInObject.stateFunction} fullscreen={true} onHide={() => valueInObject.handler(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title><Trans i18nKey={valueInObject.name} /></Modal.Title>
                            </Modal.Header>
                            <Modal.Body><object data={valueInObject.previewLink} width="100%" height="100%" aria-label={key}></object></Modal.Body>
                        </Modal>
                    </Accordion>
                </>
            );
        }

        return listArray;
    }


    return (
        <>
            <Container>
                <Row xs={1} sm={1} md={1}>
                    {createPolicyLayout()}
                </Row>
            </Container>
        </>
    )
}

export default MainBlockLayout(MainBlock);
