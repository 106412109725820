import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const UpdatedMainBlock = OriginalComponent => {
    function MainBlockLayout() {
        return (
            <>
                <Row md={12} className="align-items-center justify-content-center mainLayoutBlock">
                    <Col md={10}>
                        <OriginalComponent />
                    </Col>
                </Row>
            </>
        )
    }
    return MainBlockLayout;
}

export default UpdatedMainBlock;