import MainBlockLayout from '../../components/MainBlockLayout';
import Card from 'react-bootstrap/Card';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

function CardBody(school) {

    const styles = {
        backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.2), rgba(128, 128, 128, 0.2)), url(${school.image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        textAlign: 'center',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '10px',
        height: "420px",
    };
    
    const buttonStyle = {
        fontSize: '16px',
        fontWeight: '800',
        textAlign: 'initial',
    }
    
    const textStyle = {
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)'
    };

   
    
    return (
        <Col md={6} sm={6}>
            <Card style={styles}>
                <Card.Body>
                    <Card.Title style={{...textStyle, fontWeight: '800'}} className="justify-content-center d-flex"><Trans ns="sateliteSchools" i18nKey={school.name} /></Card.Title>
                    <Card.Text style={{...textStyle, ...buttonStyle}}>
                        <Trans ns="sateliteSchools" i18nKey={`${school.name} preview`} />
                    </Card.Text>
                </Card.Body>
                <Button  as={Link} to={`/school/${school.name}`} variant="primary"><Trans i18nKey={"visit"} /></Button>
            </Card>
        </Col>
    );
}

function MainBlock() {
    const schoolsList = useSelector(state => state.satelliteSchools.schools_list);
    let listOfSchools = () => schoolsList.map((item) => CardBody(item))

    return (
        <>
            <Row>
                {listOfSchools()}
            </Row>
        </>
    )
}

export default MainBlockLayout(MainBlock);