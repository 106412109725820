import { Row } from 'react-bootstrap';
import Marquee from "react-fast-marquee";
import { useTranslation } from 'react-i18next';

function Goline() {
    const { t } = useTranslation();

    return (
        <>
            <Row md={12} className='goLine'>
                <Marquee gradient={false} speed={60}>
                    {t('go line text')}
                </Marquee>
            </Row>
        </>
    )
}

export default Goline;