import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function SecondaryPagesImage(props) {
  return (
    <>
      <Col md={12} className="slider">
        <Card>
          <Card.Img variant="top" src={props.image} className={props.newClass} referrerPolicy="no-referrer"/>
        </Card>
      </Col>
    </>
  )
}

export default SecondaryPagesImage;