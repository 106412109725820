import { NEWS_IMAGES } from './ImagesData';

export let newsList = () => {
    let list = {};

    NEWS_OBJECT.forEach((news, index) => list[index] = news);
    return list;
}

const NEWS_OBJECT = [
    {
        header: "MBE (Member of the Order of the British Empire) award for her outstanding services to Education and the Ukrainian Community in the UK. 🇬🇧🇺🇦",
        body: "MBE body",
        preview: "MBE preview",
        photos: NEWS_IMAGES.award_mbe,
        date: '30 June 2023',
    },

    {
        header: "Уже доброю традицією для учнів Української школи Пречистої Діви Марії в Лондоні стало святкувати останній дзвоник Днем Спорту!",
        body: "Уже доброю традицією body",
        preview: "Уже доброю традицією preview",
        photos: NEWS_IMAGES.sports_day,
        date: '26 June 2023',
    },

    {
        header: "On Saturday, we welcomed a delegation from Ukraine",
        body: "we welcomed a delegation from Ukraine body",
        preview: "we welcomed a delegation from Ukraine preview",
        photos: NEWS_IMAGES.delegation_from_ukr,
        date: '26 June 2023',
    },

    {
        header: "St Mary's Ukrainian School,the winner of Mayor’s of Kensington and Chelsea Awards 2023💙💛",
        body: "Mayor’s of Kensington and Chelsea Awards 2023 body",
        preview: "Mayor’s of Kensington and Chelsea Awards 2023 preview",
        photos: NEWS_IMAGES.award_2023,
        date: '3 April 2023',
    },

    {
        header: "В переддень важливої гри з Англією, наша збірна знайшла час на позапланову зустріч з українською малечею. ",
        body: "В переддень важливої гри з Англією body",
        preview: "В переддень важливої гри з Англією preview",
        photos: NEWS_IMAGES.ukraine_team,
        date: '26 March 2023',
    },

    {
        header: "Summit 2023 \"European Horizons for Ukraine\"",
        body: "Summit 2023 body",
        preview: "Summit 2023 preview",
        photos: NEWS_IMAGES.summit_2023,
        date: '13 March 2023',
    },

    {
        header: "Коли люди мають спільну мету, то вони об’єднуються, стають сильнішими і добрішими.",
        body: "Минулоі суботи body",
        preview: "Минулоі суботи preview",
        photos: NEWS_IMAGES.first_news,
        date: '5 February 2023',
    },

    {
        header: 'Вільний Простір header',
        body: 'Вільний Простір body',
        preview: "Вільний Простір preview",
        photos: NEWS_IMAGES.prostir,
        date: '24 January 2023'
    },

    {
        header: "З Днем Соборності header",
        body: 'З Днем Соборності body',
        preview: "З Днем Соборності preview",
        photos: NEWS_IMAGES.deni_sobornosti,
        date: '22 January 2023'
    },

    {
        header: "Choir had a great honour header",
        body: "Choir had a great honour body",
        preview: "Choir had a great honour preview",
        photos: NEWS_IMAGES.choir,
        date: '17 January 2023'
    },

    {
        header: "St Mary’s Ukrainian school Woking header",
        body: "St Mary’s Ukrainian school Woking body",
        preview: "St Mary’s Ukrainian school Woking preview",
        photos: NEWS_IMAGES.school_woking,
        date: '15 January 2023'
    },

    {
        header: "Supported by the Royal Foundation header",
        body: "Supported by the Royal Foundation body",
        preview: "Supported by the Royal Foundation preview",
        photos: NEWS_IMAGES.royal_foundation,
        date: '23 December 2022'
    }
];
