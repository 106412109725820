import { Row, Col, Accordion, Button, Modal } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import NewsLetterModal from './NewsLetterModal';
import NewsLetterButton from './NewsLetterButton';
import React, { useState } from "react";

function NewsLetterAccordion({ newsLetters }) {

    function generateRow() {
        return newsLetters.map((newsLetter) => (
            newsLetter.originalName ? (
                <>
                    <Row>
                        <Col sm={12}>
                            {newsLetter.originalName}
                        </Col>
                        <Col sm={12} className="mt-2">
                            <NewsLetterButton newsLetter={newsLetter}></NewsLetterButton>
                        </Col>
                    </Row>
                    <hr></hr>
                </>
            ) : null
        ));
    }
    return (
 
 <>
            <h2 className="justify-content-center d-flex mt-5"><Trans ns="sateliteSchools" i18nKey="school brochure" /></h2>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>2023</Accordion.Header>
                    <Accordion.Body>
                       {generateRow()}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>)
}

export default NewsLetterAccordion;