import MainBlockLayout from '../../components/MainBlockLayout';
import { Button, Image, Row } from 'react-bootstrap';
import { t } from 'i18next';
import ukrainian from '../../images/ukrainian.svg';

function MainBlock() {
    const REFISTRATION_FORM_LINK = "https://forms.office.com/e/MJiE78hVGg";

    return (
        <>
            <Row className="align-items-center justify-content-center" md={5} style={{ marginBottom: '25px' }}>
                <Image src={ukrainian} />
            </Row>

            <p>Шановні Батьки,</p>
            <b>Умови реєстрації дітей до Української Школи Пречистої Діви Марії при Відділі СУБ у Лондоні.</b>
            <p>За останні кілька років число учнів Української Школи Пречистої Діви Марії значно збільшилось. Факт, що Школа набрала такої популярності має надзвичайно позитивне значення для нашої громади. Проте розмір шкільного приміщення не завжди  дозволяє прийняти усіх бажаючих навчатися в Школі, в наслідок чого Дирекція Української Школи та Управа Відділу СУБ у Лондоні розробили власні критерії вступу дітей до Школи.</p>
            <b>Критерії для вступу до Української Школи Пречистої Діви Марії при Відділі СУБ у Лондоні.</b>
            <ol>
                <li>Діти, чиї батьки активно приймають участь в житті громади або активно підтримують благочинні та волонтерські організації в Україні, а також діти членів управи СУБ, СУМ, Пласт, ОУЖ, Євромайдан Лондон.</li>
                {/*<li>Діти, брат або сестра котрих уже навчаються у Школі.</li>
                <li>Приналежність до Українських Християнських Церков.</li>
                <li>Усі інші бажаючі, які готові приймати активну участь в житті громади.</li>*/}
            </ol>

            {/*<p>Наголошуємо, що остаточне рішення про прийняття кандидата затверджує Приймальна Комісія Школи після проведення співбесіди з батьками учня .</p>
            <p>Згідну Статуту Школи вступ до класу Передшкілля можливий після того, як дитині виповнилось 5 років.</p>
            <p>Відбір учнів Приймальною Комісією Школи відбувається у травні. Анкети ,подані після  1-го травня,  розглядатимуться на наступний навчальний рік . Анкети повинні обов’язково бути подані Адміністратору Школи поштою (не електронною) або під час візиту до школи.</p>*/}
            <br>
            </br>
            <h2>Українська школа Пречистої Діви Марії St Mary's Ukrainian School продовжує НАБІР НОВИХ УЧНІВ на навчальний рік 2024/2025 💙💛, віком 3-16 років на ВСІ ЛОКАЦІЇ.</h2>

            <h3>ІНФОРМАЦІЯ ПРО ВСІ ЛОКАЦІЇ:</h3>

            <h4>💙СУБОТНІ ЛОКАЦІЇ 💛</h4>

            <ul>
            <li><b>САДОЧОК - Tadpoles- SW10 0AY- LONDON</b>
                <ul>
                <li>Години роботи:
                    <ul>
                    <li>ПЕРША зміна - 9:30-13:00;</li>
                    <li>ДРУГА зміна - 12:30-15:30;</li>
                    </ul>
                </li>
                <li>Вік учнів: 3-4 роки;</li>
                </ul>
            </li>

            <li><b>Holland Park - W11 4UH - LONDON</b>
                <ul>
                <li>Від 2-го до 5-го класу;</li>
                <li>Години роботи: 9:45-14:00;</li>
                <li>Вік учнів: 7-11 років;</li>
                <li>Передшкілля та 1-й клас;</li>
                <li>Години роботи: ДРУГА ЗМІНА - 14:00-16:45</li>
                <li>Вік учнів: 5-6 років;</li>
                </ul>
            </li>

            <li><b>Wilberforce – W10 4LB - LONDON</b>
                <ul>
                <li>Від Передшкілля до 5-го класу;</li>
                <li>Години роботи:
                    <ul>
                    <li>ОСНОВНЕ НАВЧАННЯ - 9:45-14:00</li>
                    <li>ГУРТКИ - 14:00-16:30</li>
                    </ul>
                </li>
                <li>Вік учнів: 5-11 років;</li>
                </ul>
            </li>

            <li><b>Cardinal - W14 8BZ - LONDON</b>
                <ul>
                <li>Від 6-го до 10-го класу;</li>
                <li>Години роботи: 9:45-14:15;</li>
                <li>Вік учнів: 12-16 років;</li>
                </ul>
            </li>

            <li><b>Woking - GU22 9AL - WOKING</b>
                <ul>
                <li>Від Передшкілля до 10-го класу; (ЗМІШАНІ ГРУПИ)</li>
                <li>Години роботи:
                    <ul>
                    <li>ОСНОВНЕ НАВЧАННЯ - 9:45-14:00</li>
                    <li>ГУРТКИ - 14:00-16:30</li>
                    </ul>
                </li>
                <li>Вік учнів: 5-16 років;</li>
                </ul>
            </li>

            <li><b>Tunbridge Wells - TN2 4PY - TUNBRIDGE WELLS</b>
                <ul>
                <li>Від Передшкілля до 10-го класу; (ЗМІШАНІ ГРУПИ)</li>
                <li>Години роботи:
                    <ul>
                    <li>ОСНОВНЕ НАВЧАННЯ - 9:45-14:00</li>
                    <li>ГУРТКИ - 14:00-16:30</li>
                    </ul>
                </li>
                <li>Вік учнів: 5-16 років;</li>
                </ul>
            </li>

            <li><b>Glasgow - G40 2RA - GLASGOW</b>
                <ul>
                <li>Від Передшкілля до 6-го класу; (ЗМІШАНІ ГРУПИ)</li>
                <li>Години роботи:
                    <ul>
                    <li>ОСНОВНЕ НАВЧАННЯ - 9:30-12:30</li>
                    </ul>
                </li>
                <li>Вік учнів: 5-12 років;</li>
                </ul>
            </li>

            <li><b>Northampton - NN5 6JB - NORTHAMPTON</b>
                <ul>
                <li>Від Передшкілля до 6-го класу (ЗМІШАНІ ГРУПИ);</li>
                <li>Години роботи:
                    <ul>
                    <li>ОСНОВНЕ НАВЧАННЯ - 9:45-14:00</li>
                    <li>ГУРТКИ - 14:00-15:30</li>
                    </ul>
                </li>
                <li>Вік учнів: 5-12 років</li>
                </ul>
            </li>
            </ul>

            <h4>💙💛НЕДІЛЬНІ ЛОКАЦІЇ💙💛</h4>

            <ul>
            <li><b>Richmond - TW10 7NL - RICHMOND</b>
                <ul>
                <li>Від Передшкілля до 10-го класу (ЗМІШАНІ ГРУПИ);</li>
                <li>Години роботи:
                    <ul>
                    <li>ОСНОВНЕ НАВЧАННЯ - 9:45-14:00</li>
                    <li>ГУРТКИ - 14:00-15:30</li>
                    </ul>
                </li>
                <li>Вік учнів: 5-16 років;</li>
                </ul>
            </li>

            <li><b>Kingston - KT3 3HN - KINGSTON</b>
                <ul>
                <li>Від Передшкілля до 6-го класу (ЗМІШАНІ ГРУПИ);</li>
                <li>Години роботи:
                    <ul>
                    <li>ОСНОВНЕ НАВЧАННЯ - 9:45-14:00</li>
                    <li>ГУРТКИ - 14:00-15:30</li>
                    </ul>
                </li>
                <li>Вік учнів: 5-12 років;</li>
                </ul>
            </li>

            <li><b>Hillingdon - UB10 9HT - HILLINGDON</b>
                <ul>
                <li>Від Передшкілля до 6-го класу (ЗМІШАНІ ГРУПИ);</li>
                <li>Години роботи:
                    <ul>
                    <li>ОСНОВНЕ НАВЧАННЯ - 9:45-14:00</li>
                    <li>ГУРТКИ - 14:00-15:30</li>
                    </ul>
                </li>
                <li>Вік учнів: 5-12 років;</li>
                </ul>
            </li>

            <li><b>Amersham - HP7 9HH - AMERSHAM</b>
                <ul>
                <li>Від Передшкілля до 10-го класу (ЗМІШАНІ ГРУПИ);</li>
                <li>Години роботи:
                    <ul>
                    <li>ОСНОВНЕ НАВЧАННЯ - 9:45-14:00</li>
                    <li>ГУРТКИ - 14:00-15:30</li>
                    </ul>
                </li>
                <li>Вік учнів: 5-16 років;</li>
                </ul>
            </li>
            </ul>
            <p>📣🔗👇Посилання на РЕЄСТРАЦІЙНУ ФОРМУ</p>


            <a href={REFISTRATION_FORM_LINK} target="_blank" rel="noreferrer">
                <Button variant="primary">{t('Registration Form')}</Button>
            </a>
        </>
    )
}

export default MainBlockLayout(MainBlock);